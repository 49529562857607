.files {
  display: flex;
  flex-wrap: wrap;
}

.file {
  display: block;
  margin: 25px 10px;
  &:hover {
    transform: scale(1.03);
  }
  &__container {
    display: flex;
  }
  p {
    margin: 0;
  }
  &__icon {
    width: 43px;
  }
  &__info {
    padding-left: 7px;
    width: 180px;
  }
  &__name {
    color: $black-text;
    @include font-size_font-weight(14px, 500);
    white-space: break-spaces;
  }
  &__desc {
    color: $color9d9d9d;
    @include font-size_font-weight(12px, 300);
  }
}