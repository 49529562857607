@import "web/variables";
@import "web/reset";
@import "web/mixin";
@import "web/main";

@import "web/components/breadcrumbs";
@import "web/components/cards";
@import "web/components/employee";
@import "web/components/event_news";
@import "web/components/files";
@import "web/components/gallery";
@import "web/components/main_cards";
@import "web/components/main_news";
@import "web/components/news";
@import "web/components/regular-footer";
@import "web/components/search";
@import "web/components/second_page_header";
@import "web/components/widget";

@import "web/components/header";
@import "web/components/faculties_header";
@import "web/components/footer";

@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/scrollbar";
@import "web/components/swiper";

@import "web/components/arrow";
@import "web/components/share";

@import "web/override";

@import "blueimp-gallery/css/blueimp-gallery.min.css";
@import "mburger-css/dist/mburger.css";

@import "web/components/low_vision";
@import "web/components/virtualreseption";
@import "web/components/priem";
@import "web/cookies";

:root {
  --main-color: #2A3DAD;
  --second-color: #f15f79;
  --thrid-color: #c84e89;
  --white-text: #fff;
}