
.employee {
  display: flex;
  &:hover {
    background-color: $colorE1;
  }
  &__photo {
    flex-shrink: 0;
    img {
      border-radius: 50%;
      height: 65px;
      width: 65px;
      object-fit: cover;
    }
  }
  &__info {
    padding-left: 13px;
  }
  &__name {
    margin: 0;
    @include font-size_font-weight(14px, 600);
    color: $black-text;
    line-height: 1;
  }
  &__post {
    margin: 0;
    margin-top: 7px;
    @include font-size_font-weight(12px, 300);
    font-style: italic;
    color: $black-text;
    line-height: 1;
  }
  &__phone {
    margin: 0;
    margin-top: 7px;
    @include font-size_font-weight(12px, 400);
    color: var(--main-color);
    font-style: normal;
  }
  &__widget {
    margin-bottom: 36px;
  }
  &__card {
    box-shadow: 0 0 13px 7px $colorE2;
    margin: 15px;
    padding: 20px;
    width: 100%;
    max-width: 340px;
    &:hover {
      transform: scale(1.03);
    }
  }
}

.employees {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 -15px;
}

.employee-show {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  &__photo {
    flex-shrink: 0;
    img {
      max-width: 100%;
    }
  }
  &__photo,
  &__info {
    padding-left: 20px;
  }
  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.2;
    h2 {
      margin-top: 20px;
      margin-bottom: 25px;
      font-size: 1.25em;
    }
  }
  &__about {
    p {
      margin-bottom: 7px;
    }
  }
}