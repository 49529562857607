.footer-main {
  background-color: var(--main-color)52525;
  background-image: url("../images/web/footer_bg.jpg");
  width: 100%;
}

.footer-container {
  @include flex_container(column, nowrap, flex-end, center);
  width: 100%;
  max-width: 1265px;
  padding: 50px;
  margin: 0 auto;
}

.footer-delimetr {
  margin: 30px 0;
}

.footer-logo {
  &__img {
    width: 226px;
    max-height: 75px;
  }
}

.footer-content {
  @include flex_container(row, wrap, space-between, stretch);
  width: 100%;
  &__wrapper {
    @include flex_container(column, nowrap, space-between, flex-start);
    margin-top: 15px;
    @media screen and (min-width: 500px) {
      flex-shrink: 0;
      flex-basis: 305px;
      width: 305px;
    }
  }
  &__horirantal_wrapper {
    @include flex_container(row, wrap, space-between, flex-start);
    padding-top: 8px;
    flex: 1;
  }
  &__phone {
    @include font-size_font-weight(24px, 700);
    line-height: 1.7;
    color: var(--white-text);
    &:visited, &:hover {
      color: var(--white-text);
    }
  }
  &__address {
    @include font-size_font-weight(12px, 500);
    color: var(--white-text);
  }
}

.footer-links {
  width: 180px;
  @include nomargin_nopadding;
  // margin-right: 35px;
  margin-top: 15px;
  &__list {
    @include nomargin_nopadding;
  }
  &__title {
    @include font-size_font-weight(18px, 700);
    text-transform: uppercase;
    color: var(--white-text);
  }
  &__item {
    list-style: none;
    margin-top: 10px;
    line-height: 0.8;
    a {
      @include font-size_font-weight(10px, 500);
      @include link_on_hover_underline(var(--white-text));
      text-transform: uppercase;
    }
  }
}

.footer-social-networks {
  @include flex_container(row, wrap, flex-start, flex-end);
  &__link {
    display: block;
    color: var(--white-text);
    transition: all 0.3s;
    img {
      fill: var(--white-text) !important;
      color: var(--white-text) !important;
    }
    img path {
      fill: var(--white-text) !important;
      color: var(--white-text) !important;
    }
    &:hover {
      transform: translateY(-2px);
    }
  }
}

.footer-copyright {
  @include flex_container(row, wrap, space-between, flex-start);
  width: 100%;
  span,
  a {
    @include font-size_font-weight(12px, 500);
    margin: 0 15px 15px 15px;
    @include link_on_hover_underline(var(--white-text));
  }
}
