.share {
  display: flex;
  // border-top: 1px solid $colore9e9e9;
  border-bottom: 1px solid $colore9e9e9;
  padding: 7px 0;
  align-items: center;
  &__text {
    @include font-size_font-weight(18px, 600);
    margin-right: 10px;
  }
  &__list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  &__link {
    display: block;
    color: var(--second-color);
    width: 32px;
    height: 32px;
    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
