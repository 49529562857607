@mixin lowVision($color, $bgColor) {
  color: $color;
  background-color: $bgColor;
}

.o-low-visible {
  display: none;
}

.sv_wrapper {
  display: none;
  // justify-content: center;
  position: fixed;
  width: 100%;
  top: 95px;
  z-index: 2;
  line-height: 1;
  overflow-x: auto;
}

@media screen and (min-width: 900px) {
  .o-low-visible {
    display: block;
    align-items: center;
    background: linear-gradient(199.01deg, var(--main-color) -11.37%, var(--main-color) 302.8%);
    border-radius: 50%;
    bottom: 15px;
    display: flex;
    height: 66px;
    justify-content: center;
    position: fixed;
    right: 15px;
    transition: 0.3s;
    width: 66px;
    z-index: 2;
    &:hover {
      opacity: 0.8;
    }
  }

  .sv_settings {
    // padding: 10px;
    display: flex;
    font-size: 22px;
    width: 1820px;
    background-color: white;
    text-align: center;
    justify-content: space-around;
    white-space: nowrap;
  }
  .sv_settings * {
    font-weight: bold;
    line-height: 1;
  }
  .fs-outer {
    display: flex;
    justify-content: space-around;
  }
  .fs-outer button {
    // display: inline-block;
    background: white !important;
    color: black !important;
    height: 45px;
    width: 45px;
    padding: 0;
    line-height: 1;
  }
  .img-onoff {
    background: transparent !important;
    border: 0 !important;
    box-shadow: unset;
    line-height: 1;
  }
  .img-outer {
    display: flex;
    align-items: flex-end;
  }
  .fs-n {
    font-size: 16px;
  }
  .fs-m {
    font-size: 20px;
  }
  .fs-l {
    font-size: 24px;
  }

  .cs-outer {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }
  .cs-outer button {
    display: inline-block;
    height: 45px;
    width: 45px;
    padding: 0;
    line-height: 1;
  }
  .mgl20 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .cs-bw,
  .cs-bw:focus,
  .cs-bw:hover {
    @include lowVision(black, white);
  }
  .cs-wb,
  .cs-wb:focus,
  .cs-wb:hover {
    @include lowVision(white, black);
  }
  .cs-bb,
  .cs-bb:focus,
  .cs-bb:hover {
    @include lowVision(#063462, #9dd1ff);
  }
  .cs-gb,
  .cs-gb:focus,
  .cs-gb:hover {
    @include lowVision(#4d4b43, #f7f3d6);
  }
  .cs-yg,
  .cs-yg:focus,
  .cs-yg:hover {
    @include lowVision(#a9e44d, #3b2717);
  }
  .img-onoff {
    color: inherit;
  }

  .lowVisionColorEnable {
    color: var(--second-color) !important;
    background-color: var(--main-color) !important;
    background: var(--main-color) !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    span,
    br,
    ul,
    li,
    table,
    tr,
    td,
    a,
    div {
      color: var(--second-color) !important;
    }
    .header-faculties__lk-text {
      color: var(--second-color) !important;
    }
    .header,
    .sv_settings {
      background-color: var(--main-color) !important;
    }
    .main-card,
    .card,
    .main-news-image,
    .news-item {
      border: 5px solid var(--second-color);
    }
    .sv_wrapper {
      border-top: 5px solid var(--second-color);
      border-bottom: 5px solid var(--second-color);
    }

    .sv_settings .closebtn {
      font-size: 52px;
      margin: 0;
      padding: 0;
    }
    .o-low-visible {
      display: none;
    }
    .widgets,
    .share {
      border: unset !important;
    }
    .widget__border,
    .events-news {
      display: none;
    }
    .main-news-image__text,
    .main-news-item.active,
    .news-tags__item,
    .news_show__tags-show,
    .news_show__tags-hidden,
    .second-header,
    .footer-main,
    .breadcrumbs__back,
    .gallery,
    .priem-header,
    .regular-footer {
      background: unset !important;
      background-image: unset !important;
    }
    .breadcrumbs__back:before {
      border: unset !important;
    }
    .main-card,
    .card,
    .main-news-image,
    .news-item {
      background-image: none !important;
    }
    .news-item {
      height: unset;
    }
    .news-item-image .news-item__content,
    .gallery__info {
      background-image: none !important;
    }

    .lowVisionDisplayNone {
      display: none !important;
    }
    .override table > tbody > tr:hover > * {
      --bs-table-accent-bg: unset;
    }
    .override table > tbody > tr:nth-of-type(odd) > * {
      --bs-table-accent-bg: unset;
    }
    table,
    tr,
    td,
    th,
    hr {
      border-color: var(--second-color) !important;
    }
    .mobile__menu__title {
      background-color: var(--main-color);
    }
    .mobile__menu__link-back {
      color: var(--main-color) !important;
    }
    .employee:hover {
      background-color: var(--main-color);
    }
    .background-main-page {
      display: none;
    }
  }

  body.lowVisionEnableMiddleLineHeight {
    line-height: 2;
    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
      line-height: 1.5;
    }
    .footer-links__item {
      line-height: 2;
    }
    .main-card__item {
      line-height: 2.5;
    }
    .main-news-image__text {
      h4 {
        line-height: 1.8;
      }
      span {
        line-height: 2;
      }
    }
    .main-news-item__desc {
      line-height: 2;
    }

    .second-header__pretitle,
    .second-header__title {
      line-height: 1.5;
    }
    .dropdown-menu__link {
      margin-bottom: 30px !important;
    }
  }

  body.lowVisionEnableBigLineHeight {
    line-height: 2.5;
    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
      line-height: 2;
    }
    .footer-links__item {
      line-height: 2.5;
    }
    .main-card__item {
      line-height: 3;
    }
    .main-news-image__text {
      h4 {
        line-height: 2.3;
      }
      span {
        line-height: 2.5;
      }
    }
    .main-news-item__desc {
      line-height: 2.5;
    }

    .second-header__pretitle,
    .second-header__title {
      line-height: 2;
    }
    .dropdown-menu__link {
      margin-bottom: 40px !important;
    }
  }

  body.Arial {
    font-family: Arial;
  }

  body.Times {
    font-family: "Times New Roman";
  }
}
