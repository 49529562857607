.second-header {
  @media screen and (min-width: $container-size) {
    padding-top: 100px;
  }
  color: var(--white-text);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 400px;

  @media (min-width: $sm) {
    min-height: 400px;
  }

  @media (min-width: $md) {
    min-height: 400px;
  }

  @media (min-width: $lg) {
    min-height: 550px;
  }

  @media (min-width: $xl) {
    min-height: 630px;
  }

  &__container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
  }

  &__text {
    width: 100%;
    display: block;
    padding: 0 15px;
    @media screen and (min-width: $container-size) {
      padding: 0;
    }
  }

  &__pretitle {
    @include font-size_font-weight(14px, 700);
    line-height: 1.2;
    margin-bottom: 20px;

    @media (min-width: $sm) {
      @include font-size_font-weight(18px, 700);
    }

    @media (min-width: $lg) {
      @include font-size_font-weight(24px, 700);
    }
  }

  &__title {
    @include font-size_font-weight(24px, 700);
    line-height: 1.2;

    @media (min-width: $sm) {
      @include font-size_font-weight(32px, 700);
    }

    @media (min-width: $lg) {
      @include font-size_font-weight(60px, 700);
    }
  }
}
