.ck-content {
  p {
    margin-bottom: 1rem;
    margin-top: 0;
    word-break: break-word;
  }
  a {
    color: var(--main-color);
    &:hover {
      text-decoration: underline;
      color: var(--main-color);
    }
  }
  img {
    max-width: 100%;
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5 {
    margin-bottom: 10px !important;
    margin-top: 15px !important;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 10px;
      background: url("../images/web/ul_li.png") no-repeat 0 4px;
      padding-left: 24px;
      @include font-size_font-weight(14px, 500);
    }
  }

  table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    font-size: 0.9rem;
    caption-side: bottom;
    border-collapse: collapse;
    vertical-align: center;
  }

  table > :not(:first-child) {
    border-top: 2px solid currentColor;
  }
  table > :not(caption) > * {
    border-width: 1px 0;
  }
  table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    border-width: 0 1px;
  }

  table > tbody {
    vertical-align: inherit;
  }

  table > thead {
    vertical-align: bottom;
  }
  table > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
  }

  table > tbody > tr:hover > * {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
  }
  tr,
  td,
  th {
    word-break: break-word;
  }
  tr p,
  td p,
  th p {
    word-break: inherit;
  }

  > ul {
    padding: 0;
    margin-top: 18px;
  }

  blockquote {
    margin: 0;
    padding-left: 30px;
    border-left: 2px solid #909090;
    font-style: italic;
    @include font-size_font-weight(16px, 500);
  }
}
:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

.ck-content {
  .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      min-width: 100%;
    }
  }

  .image-style-block-align-left,
  .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
  }

  .image-style-align-left,
  .image-style-align-right {
    clear: none;
  }

  .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }

  .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
  }

  .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
  }

  p + {
    .image-style-align-left,
    .image-style-align-right,
    .image-style-side {
      margin-top: 0;
    }
  }

  .image-inline {
    &.image-style-align-left,
    &.image-style-align-right {
      margin-top: var(--ck-inline-image-style-spacing);
      margin-bottom: var(--ck-inline-image-style-spacing);
    }

    &.image-style-align-left {
      margin-right: var(--ck-inline-image-style-spacing);
    }

    &.image-style-align-right {
      margin-left: var(--ck-inline-image-style-spacing);
    }
  }

  .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
  }

  .text-tiny {
    font-size: 0.7em;
  }

  .text-small {
    font-size: 0.85em;
  }

  .text-big {
    font-size: 1.4em;
  }

  .text-huge {
    font-size: 1.8em;
  }

  .image-inline {
    /*
       * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
       * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
       * This strange behavior does not happen with inline-flex.
       */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;

    picture {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 100%;
    }

    img {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 100%;
    }
  }

  .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;

    img {
      width: 100%;
    }

    > figcaption {
      display: block;
    }
  }

  .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
  }

  .marker-green {
    background-color: var(--ck-highlight-marker-green);
  }

  .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
  }

  .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
  }

  .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
  }

  .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
  }

  &[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
  }

  code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: 0.15em;
    border-radius: 2px;
  }

  .table {
    margin: 0.9em auto;
    display: table;

    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      height: 100%;

      td {
        min-width: 2em;
        padding: 0.4em;
      }

      th {
        min-width: 2em;
        padding: 0.4em;
        font-weight: bold;
      }
    }
  }

  &[dir="rtl"] .table th {
    text-align: right;
  }

  &[dir="ltr"] .table th {
    text-align: left;
  }

  .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
  }

  .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      border-bottom: 2px dashed hsl(0, 0%, 77%);
      width: 100%;
    }
  }

  .page-break__label {
    position: relative;
    z-index: 1;
    padding: 0.3em 0.6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
  }

  .todo-list {
    list-style: none;

    li {
      margin-bottom: 5px;

      .todo-list {
        margin-top: 5px;
      }
    }

    .todo-list__label {
      > input {
        -webkit-appearance: none;
        display: inline-block;
        position: relative;
        width: var(--ck-todo-list-checkmark-size);
        height: var(--ck-todo-list-checkmark-size);
        vertical-align: middle;
        border: 0;
        left: -25px;
        margin-right: -15px;
        right: 0;
        margin-left: 0;

        &::before {
          display: block;
          position: absolute;
          box-sizing: border-box;
          content: "";
          width: 100%;
          height: 100%;
          border: 1px solid hsl(0, 0%, 20%);
          border-radius: 2px;
          transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
        }

        &::after {
          display: block;
          position: absolute;
          box-sizing: content-box;
          pointer-events: none;
          content: "";
          left: calc(var(--ck-todo-list-checkmark-size) / 3);
          top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
          width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
          height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
          border-style: solid;
          border-color: transparent;
          border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
          transform: rotate(45deg);
        }

        &[checked] {
          &::before {
            background: hsl(126, 64%, 41%);
            border-color: hsl(126, 64%, 41%);
          }

          &::after {
            border-color: hsl(0, 0%, 100%);
          }
        }
      }

      .todo-list__label__description {
        vertical-align: middle;
      }
    }
  }

  hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
  }

  span[lang] {
    font-style: italic;
  }

  pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;

    code {
      background: unset;
      padding: 0;
      border-radius: 0;
    }
  }

  .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
  }
}
