.bottom-cards,
.top-cards {
  margin: 0px 0 30px 0;
  @media screen and (min-width: 650px) {
    margin: 0px 45px 30px 45px;
  }
  position: relative;
}
.card {
  @include flex_container(column,wrap,center,center);
  width: 290px;
  height: 136.7px;
  padding-left: 90px;
  padding-right: 10px;
  overflow: hidden;
  @media screen and (min-width: 650px) {
    width: 350px;
    height: 165px;
    padding-left: 150px;
  }
  color: var(--white-text);
}

.card__title {
  @include font-size_font-weight(18px, 500);
}

.card-title {
  @include font-size_font-weight(24px, 600);
}