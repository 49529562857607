.main-cards {
  @include flex_container(column, wrap, space-around, center);
  width: 100%;
  @media screen and (min-width: $container-size) {
    flex-direction: row;
  }
}
.main-card {
  @include flex_container(column, nowrap, space-between, flex-start);
  position: relative;
  max-width: 357px;
  min-height: 250px;
  max-height: 566px;
  width: 100%;
  height: calc((100vw - $base-page-pading * 2) / (357px / 566px * 1.2));
  // @media screen and (min-width: 1100px) {
  //   height: calc((100vw - $base-page-pading * 2) / (357px / 566px));
  // }
  margin-bottom: 15px;
  padding: 15px;
  overflow: hidden;
  background-size: cover;
  @media screen and (min-width: 390px) {
    padding: 50px 23px 36px 21px;
  }
  &__title {
    @include font-size_font-weight(24px, 800);
    word-break: break-word;
    color: var(--white-text);
    @media screen and (min-width: 390px) {
      @include font-size(36px);
    }
  }
  &__links {
    @include nomargin_nopadding;
    list-style: none;
  }
  &__item {
    @include font-size_font-weight(16px, 500);
    line-height: 2;
    a {
      @include link_on_hover_underline(var(--white-text));
    }
  }
}

