.breadcrumbs {
  width: 100%;
  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px 10px 15px;
    @media (min-width: $container-size) {
      padding: 15px 0px 5px 0;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    a {
      position: relative;
      @include font-size_font-weight(14px, 500);
      color: var(--second-color);
      text-decoration: underline;
      margin-right: 30px;
      word-break: break-word;
      &:hover {
        text-decoration: none;
      }
    }
    .with-arrow-right-no-animation {
      &:after {
        margin-left: 10px;
      }
    }
    span {
      @include font-size_font-weight(14px, 500);
      color: $color9d9d9d;
    }
  }
  &__back {
    position: relative;
    @include font-size_font-weight(14px, 500);
    display: block;
    padding: 8px 10px 8px 0;
    background-color: var(--main-color);
    color: var(--white-text);
    margin-left: 15px;
    line-height: 1;
    span {
      // white-space: pre;
    }
    &:before {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: $colorrgba1;
      border-right-color: var(--main-color);
      border-width: 15px;
      margin-top: -15px;
    }
    &:hover {
      transform: scale(1.03);
    }
  }
  &__hr {
    margin: 10px 0 0 0;
    opacity: 0.1;
    border-top: 1px solid $color89;
  }
}
