.galleries {
  width: 100%;
  @include flex_container(column, nowrap, flex-start, center);
  &__content {
    @include flex_container(row, wrap, center, flex-start);
  }
  &__more {
    display: block;
    margin-top: 25px;
    text-align: center;
    color: $black-text;
    @include font-size_font-weight(16px, 600);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.gallery {
  @include flex_container(column, nowrap, flex-end, flex-start);
  position: relative;
  margin: 5px;
  transition: 0.2s;
  width: 290px;
  height: 290px;
  @media  screen and (min-width: 768px) {
    width: 360px;
    height: 360px;
  }
  &:hover {
    .gallery__title {
      text-decoration: underline;
    }
    transform: scale(1.03);
  }
  &__info {
    width: 100%;
    color: var(--white-text);
    padding: 15px;
    background-image: linear-gradient(transparent, $colorrgba2);
  }
  &__title {
    @include font-size_font-weight(16px, 500);
    margin: 0;
    margin-bottom: 35px;
  }
  &__date {
    @include font-size_font-weight(12px, 400);
    margin: 0;
  }
}

.gallery-show {
  &__title {
    @include font-size_font-weight(24px, 600);
    margin: 0;
  }
  &__count {
    @include font-size_font-weight(12px, 400);
    margin: 0;
  }
  &__text {
    margin: 35px 0;
  }
  &__images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    img {
      vertical-align: bottom;
      margin: 10px;
    }
  }
}
