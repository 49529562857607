.main-news {
  position: relative;
  margin-bottom: 30px;
  @media (min-width: 1300px) {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 0px;
  }
}
.main-news-swiper {
  display: none;
  @media (min-width: 500px) {
    display: block;
  }
  @media (min-width: 1300px) {
    flex-basis: 850px;
  }
}

.main-news-images {
  display: flex;
  justify-content: center;
}
.main-news-image {
  position: relative;
  display: inline-block;
  max-width: 850px;
  width: 100%;
  height: calc((100vw - $base-page-pading * 2) / (850px / 615px));
  max-height: 615px;
  background-size: contain;
  &__text {
    @include absolute_full_container;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--white-text);
    padding: 30px 30px 30px 30px;
    background-image: linear-gradient(transparent, $colorrgba2);
    h4 {
      @include font-size_font-weight(18px, 600);
      letter-spacing: 0.03em;
      margin-bottom: 15px;
      line-height: 1.4;
    }
    span {
      @include font-size_font-weight(16px, 500);
      margin-bottom: 25px;
      line-height: 1.7;
    }
    a {
      position: relative;
      color: var(--white-text);
      text-decoration: none;
      @include font-size_font-weight(16px, 600);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (min-width: 650px) {
    &__text {
      h4 {
        @include font-size(24px);
      }
      span {
        margin-bottom: 58px;
      }
    }
  }
  @media screen and (min-width: 880px) {
    &__text {
      padding: 30px 60px 67px 60px;
      h4 {
        @include font-size(30px);
        margin-bottom: 12px;
      }
    }
  }
}

.main-news-info {
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 500px) {
    display: none;
  }
  @media (min-width: 1300px) {
    display: flex;
    flex-direction: column;
    height: 616px;
    margin-left: 30px;
    justify-content: space-between;
    flex: 1;
  }
  &__title {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 5px;
    @media (min-width: 1300px) {
      padding-top: 46px;
      margin-bottom: 50px;
    }
  }
  &__name {
    @include font-size_font-weight(24px, 500);
    direction: ltr;
    @media (min-width: 1300px) {
      padding-left: 60px;
      padding-top: 2px;
    }
  }
}

.main-news-items {
  @media (max-width: 1300px) {
    display: flex;
    // max-height: 250px;
    overflow-x: scroll;
    padding-bottom: 20px;
    @include hscrollbar(var(--second-color), $colorebedf3);
  }

  @media (min-width: 1300px) {
    direction: rtl;
    padding-left: 44px;
    flex: 1;
    overflow-y: scroll;
    @include scrollbar(var(--second-color), $colorebedf3);
  }
}

.main-news-item {
  direction: ltr;
  display: flex;
  color: $black-text;
  text-decoration: none;
  min-width: 240px;
  
  @media (min-width: 1300px) {
    padding: 20px 25px;
    &.active {
      background: linear-gradient(90deg, var(--thrid-color) 0%, var(--second-color) 100%), $coloracb8e6;
      color: var(--white-text);
    }
  }

  &__date {
    @include font-size_font-weight(14px, 400);
  }
  &__wrapper {
    padding-left: 14px;
  }
  &__title {
    @include font-size_font-weight(16px, 700);
    margin-bottom: 14px;
  }
  &__desc {
    @include font-size_font-weight(14px, 400);
    line-height: 1.7;
  }
}

.main-news__swiper-scrollbar {
  max-width: 65%;
  display: none;
  @media (min-width: 500px) {
    display: block;
  }
  @media (min-width: 1300px) {
    display: none;
  }
}
