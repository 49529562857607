.swiper-scrollbar {
  bottom: -20px;
  width: 75%;
  max-width: 348px;
  height: 4px;
  margin: 0 auto;
  background-color: $colorebedf3;
  border-radius: 50px;
  cursor: pointer;
  opacity: 1 !important;
  &-drag {
    background: var(--second-color) !important;
    border-radius: 50px;
  }
}
.swiper-button-next {
  right: -45px;
  color: var(--second-color);
  display: none;
  z-index: 0 !important;
  @media screen and (min-width: 650px) {
    display: block;
  }
}
.swiper-button-prev {
  left: -45px;
  color: var(--second-color);
  display: none;
  z-index: 0 !important;
  @media screen and (min-width: 650px) {
    display: block;
  }
}
