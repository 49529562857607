%with-arrow {
  &:after {
    display: inline-block;
    clear: both;
    padding: 0.2em;
    border: solid $black-text;
    border-width: 0 0.2em 0.2em 0;
    border-color: inherit;
    content: " ";
    margin-left: 4px;
    transition-duration: 250ms;
  }
  &:hover {
    &:after {
      transform: translate(0%, -15%) rotate(45deg);
    }
  }
}

.with-arrow-right {
  @extend %with-arrow;
  &:after {
    transform: translate(0%, -15%) rotate(-45deg);
  }
}
.with-arrow-right-no-animation {
  &:after {
    display: inline-block;
    clear: both;
    padding: 0.2em;
    border: solid $black-text;
    border-width: 0 0.2em 0.2em 0;
    border-color: inherit;
    content: " ";
    margin-left: 4px;
    transition-duration: 250ms;
    transform: translate(0%, -15%) rotate(-45deg);
  }
}
.with-arrow-left {
  &:before {
    display: inline-block;
    clear: both;
    padding: 0.2em;
    border: solid $black-text;
    border-width: 0 0.2em 0.2em 0;
    border-color: inherit;
    content: " ";
    margin-right: 4px;
    transition-duration: 250ms;
    transform: translate(0%, -15%) rotate(135deg);
  }
  &:hover {
    &:before {
      // transform: translate(0%, -15%) rotate(45deg);
    }
  }
}
.with-arrow-down {
  @extend %with-arrow;
  &:after {
    transform: translate(0%, -15%) rotate(45deg);
  }
}
.with-arrow-up {
  @extend %with-arrow;
  &:after {
    transform: translate(0%, -15%) rotate(-135deg);
  }
}
