.priem-header {
  @media screen and (min-width: $container-size) {
    height: 1000px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #183ab1;
  margin: 0 (-$base-page-pading);
  padding: 0 $base-page-pading;
  background-image: url("../images/web/priem_header.png");
  &__wrapper {
    padding-top: 150px;
    @media screen and (min-width: $container-size) {
      padding: 0;
    }
    width: 100%;
    max-width: 1120px;
    padding-bottom: 150px;
  }
  &__title {
    @include font-size_font-weight(24px, 400);
    color: var(--white-text);
  }
  &__subtitle {
    color: #183ab1;
    @include font-size_font-weight(42px, 700);
    @media screen and (min-width: 650px) {
      @include font-size_font-weight(95px, 700);
    }
    text-shadow: 0 0 3px var(--white-text);
    span {
      color: var(--white-text);
      text-shadow: none;
    }
  }
  &__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &__document {
    margin-top: 20px;
    border: 1px solid var(--white-text);
    border-radius: 4px;
    background: var(--main-color);
    color: var(--white-text);
    padding: 10px;
    @media screen and (min-width: 650px) {
      padding: 23px 65px;
    }
    @include font-size_font-weight(18px, 500);
    &:hover {
      text-decoration: underline;
    }
  }
  &__profession {
    margin-top: 20px;
    color: var(--white-text);
    padding: 10px;
    @media screen and (min-width: 650px) {
      padding: 23px 65px;
    }
    @include font-size_font-weight(18px, 500);
    &:hover {
      text-decoration: underline;
    }
  }
}

.priem-cards {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 45px;
    margin-bottom: 20px;
  }
  &__title {
    margin-top: 20px;
    color: var(--main-color);
    @include font-size_font-weight(36px, 600);
    text-align: center;
  }
  &__footer {
    width: 100%;
    overflow-x: hidden;
    margin-top: 40px;
    margin-bottom: 25px;
  }
}

.priem-contact {
  margin: 40px 0;
  &__title {
    @include font-size_font-weight(36px, 600);
  }
  &__subtitle {
    @include font-size_font-weight(18px, 400);
  }
  &__employees {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .employee__widget {
      width: 100%;
      max-width: 250px;
    }
  }
}

.position-absolute-priem {
  position: absolute !important;
  @media screen and (min-width: $container-size) {
    position: fixed !important;
  }
}