.header-faculties {
  .header__content {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.header-faculties__lk {
  padding: 10px 20px;
  color: var(--white-text);
  background-color: var(--main-color);
  border-radius: 25px;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
  &:hover {
    transform: scale(1.03);
  }
  display: none;
  @media screen and (min-width: 1250px) {
    display: block;
  }
}
.header-faculties__title {
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
  }
}
.header-faculties__content {
  display: flex;
  flex: 1;
  align-items: center;
}
.header-faculties__faculties {
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  list-style: none;
  a {
    color: $black-text;
    @include font-size_font-weight(16px, 600);
    &:hover {
      text-decoration: underline;
    }
  }
  li {
    margin-bottom: 20px;
  }
}

.header__mobile-title {
  display: block;
  @include font-size_font-weight(18px, 500);
  white-space: normal;
  text-align: center;
  margin: 10px 0;
  @media screen and (min-width: $container-size) {
    display: none;
  }
}
#select_fak {
  display: none;
  @media screen and (min-width: $container-size) {
    display: flex;
  }
}