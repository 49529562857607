html, body {
  font-size: 16px;
  position: relative;
  font-family: "Montserrat";
}
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.content {
  flex: 1;
  padding: 0 $base-page-pading;
  max-width: $max-container-size;
  width: 100%;
  margin: 0 auto;
}
.container {
  width: 100%;
  max-width: $container-size;
  margin: 0 auto;
}
.container-fluid {
  max-width: $container-fluid;
  margin: 0 auto;
}
.container-max {
  margin: 0 auto;
}
.d-none {
  display: none !important;
}
.main-page-padding {
  @media screen and (min-width: $container-size) {
    padding-top: 50px;
  }
}
.content-secondary-page {
  padding-top: 40px;
  padding-bottom: 50px;
}
.content-top_bottom_margin {
  margin: 20px 0;
  @media screen and (min-width: $container-size) {
    margin: 80px 0;
  }
}
.black-link {
  color: $black-text;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.hr-white {
  border: 1px solid var(--white-text);
  opacity: 0.2;
  width: 100%;
}
.second-content-padding {
  padding: 50px 0;
}
.no-wrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-muted {
  color: rgb(121, 121, 121);
}
.contact_social_icon {
  color: var(--main-color);
}
.page__content {
  display: flex;
  flex-direction: column;
}
.mt-5px {
  margin-top: 5px;
}
.space-around {
  justify-content: space-around;
}
.color-black {
  color: #000;
}