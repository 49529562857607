.search {
  display: flex;
  width: 100%;
  box-shadow: 9px 11px 26px $colorrgba3;
  &__content {
    position: relative;
    display: inline-block;
    flex: 1;
    padding: calc(100% / $container-size * 43px) 31px;
    overflow: hidden;
    cursor: text;
    .search__input {
      width: 100%;
      @include font-size_font-weight(16px, 500);
      font-family: "Montserrat";
      border: 0;
      outline: none;
      &:focus {
        border: 0;
        outline: none;
      }
    }
    & > input[type="text"]:placeholder-shown + label {
      display: block;
    }
  }
  .search__placeholder {
    position: absolute;
    top: 50%;
    left: 32px;
    display: none;
    margin-top: -0.5em;
    cursor: text;
  }
  .search__placeholder > span {
    @include font-size_font-weight(16px, 500);
    letter-spacing: 0em;
  }
  .search__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    max-width: 181px;
    color: var(--white-text);
    @include font-size_font-weight(16px, 500);
    background: linear-gradient(114.07deg, $coloracb6e5 15.44%, $color86fde8 122.33%);
    border: 0;
    cursor: pointer;
    span {
      margin-left: 10px;
    }
    svg {
      width: 14px;
      @media screen and (min-width: 570px) {
        width: 21px;
      }
    }
  }
}
.first-letter {
  color: $colorc1c1c1;
  @media screen and (min-width: 570px) {
    color: $black-text;
  }
}
.second-letter {
  display: none;
  color: $colorc1c1c1;
  @media screen and (min-width: 570px) {
    display: inline;
  }
}
