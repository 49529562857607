.virtual-reception__form {
  background-color: var(--main-color);
  font-family: "Montserrat";
  padding: 20px;
  width: calc(100% + $base-page-pading * 2);
  margin: 0px (-$base-page-pading);
}

.virtual-reception__wrapper {
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.virtual-reception__tab {
  display: none;
  width: 100%;
}

.virtual-reception__tab-active {
  display: block;
}

.virtual-reception__title {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  margin: 0 0 21px 0;
  color: var(--white-text);
}

.virtual-reception__subtitle {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  color: var(--white-text);
}

.virtual-reception__inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 28px;
  column-gap: 35px;
}

.virtual-reception__full-width {
  width: 100%;
}

.virtual-reception__btn {
  background-color: var(--main-color);
  padding: 23px 75px;
  max-width: 330px;
  width: 100%;
  border: 1px solid #a3a3a3;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: var(--white-text);
  font-size: 19px;
  cursor: pointer;
}

.virtual-reception__btn:hover {
  opacity: 0.8;
}

.virtual-reception__wrapper input,
.virtual-reception__wrapper select,
.virtual-reception__wrapper textarea {
  font-family: "Montserrat";
  font-size: 19.5px;
  font-weight: 400;
  background: linear-gradient(270.25deg, rgba(160, 160, 160, 0.2) 0.14%, rgba(255, 255, 255, 0.2) 99.83%);
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 20px;
  color: var(--white-text);
}

.virtual-reception__wrapper select {
  cursor: pointer;
}

.virtual-reception__wrapper input,
.virtual-reception__wrapper input:focus-visible,
.virtual-reception__wrapper select:focus-visible,
.virtual-reception__wrapper select,
.virtual-reception__wrapper textarea,
.virtual-reception__wrapper textarea:focus-visible {
  outline: none;
  // border: none;
}

.virtual-reception__wrapper input::placeholder,
.virtual-reception__wrapper textarea::placeholder {
  color: #ccc;
}

.virtual-reception__wrapper select option {
  font-size: 16px;
  color: var(--white-text);
  background-color: var(--main-color);
  font-family: "Montserrat";
}

.virtual-reception__wrapper select option:disabled {
  color: #ccc;
}

.virtual-reception__help {
  display: block;
  color: var(--white-text);
  font-family: "Montserrat";
}

.virtual-reception__help a {
  color: var(--white-text);
  text-decoration: underline;
}

.virtual-reception__help a:hover {
  color: var(--white-text);
  text-decoration: none;
}

.virtual-reception__wrapper textarea {
  height: 117px;
}

.virtual-reception__block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
}

.virtual-reception__checkbox label {
  color: var(--white-text);
}

.virtual-reception__block,
.virtual-reception__btns,
.virtual-reception__title,
.virtual-reception__subtitle,
.virtual-reception__help {
  margin: 10px 0;
}

.virtual-reception__block-four input,
.virtual-reception__block-four select {
  width: 100%;
  max-width: 480px;
  margin-bottom: 20px;
}

.virtual-reception__error {
  border: 2px solid rgb(148, 1, 1) !important;
}

.virtual-reception__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.virtual-reception__loading-ul {
  width: 108px;
  height: 108px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.virtual-reception__loading-ul .virtual-reception__loading-li {
  width: 30px;
  height: 30px;
  margin: 2px;
  background-color: transparent;
  animation: virtual-reception__loading-animation 0.8s infinite;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.virtual-reception__loading-ul .virtual-reception__loading-li:nth-child(5) {
  opacity: 0;
}

.virtual-reception__loading-ul .virtual-reception__loading-li:nth-child(1) {
  animation-delay: 0.1s;
}

.virtual-reception__loading-ul .virtual-reception__loading-li:nth-child(2) {
  animation-delay: 0.2s;
}

.virtual-reception__loading-ul .virtual-reception__loading-li:nth-child(3) {
  animation-delay: 0.3s;
}

.virtual-reception__loading-ul .virtual-reception__loading-li:nth-child(6) {
  animation-delay: 0.4s;
}

.virtual-reception__loading-ul .virtual-reception__loading-li:nth-child(9) {
  animation-delay: 0.5s;
}

.virtual-reception__loading-ul .virtual-reception__loading-li:nth-child(8) {
  animation-delay: 0.6s;
}

.virtual-reception__loading-ul .virtual-reception__loading-li:nth-child(7) {
  animation-delay: 0.7s;
}

.virtual-reception__loading-ul .virtual-reception__loading-li:nth-child(4) {
  animation-delay: 0.8s;
}

@keyframes virtual-reception__loading-animation {
  1% {
    background-color: var(--main-color);
  }
}

.virtual-reception__form a {
  color: var(--white-text);
  text-decoration: underline;
}
