.regular-footer {
  background-color: #3d3d3d;
  width: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  a {
    color: var(--white-text);
  }
  &__logo {
    flex-shrink: 0;
  }
  &__main-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    a {
      margin: 0 20px;
    }
  }
  &__main {
    width: 100%;
    border-top: 2px solid $color9d9d9d;
    border-bottom: 2px solid $color9d9d9d;
    padding: 20px;
    margin: 20px 0;
  }
  &__links {
    margin: 0 auto;
    // max-width: 930px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-gap: 15px 50px;
    a {
      word-break: break-word;
    }
  }
  &__copyright {
    padding: 0 20px;
    width: 100%;
    @include font-size_font-weight(12px, 400);
    color: var(--white-text);
    // text-align: center;
    display: flex;
    justify-content: space-between;
  }
}
