@mixin link_on_hover_underline($color) {
  color: $color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: $color;
  }
}
@mixin nomargin_nopadding {
  margin: 0;
  padding: 0;
}
@mixin absolute_full_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
@mixin flex_container($direction, $wrap, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $align;
}
@mixin scrollbar($color_1, $color_2) {
  scrollbar-color: $color_1 $color_2;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: $color_2;
    border-radius: 16px;
    left: 0;
    // direction: rtl !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color_1;
  }
}
@mixin hscrollbar($color_1, $color_2) {
  scrollbar-color: $color_1 $color_2;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: $color_2;
    border-radius: 16px;
    left: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color_1;
  }
}

@mixin font-size_font-weight($font-size, $font-weight) {
  font-size: calc($font-size / $font-size-base) + rem;
  font-weight: $font-weight;
}

@mixin font-size($font-size) {
  font-size: calc($font-size / $font-size-base) + rem;
}
