$max-container-size: 1920px;
$container-fluid: 1700px;
$container-size: 1110px;
$font-size-base: 16px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$white-text: #fff;
$base-page-pading: 15px;

$black-text: #212529;
$color89: #242424; //breadcrumbs hr, header
$colorE2: #00000017; //dont change
$color3d3d3d: #3d3d3d;
$color152525: #252525;
$colorrgba1: rgba(194, 225, 245, 0);
$colorrgba2: rgba(0, 0, 0, 0.8);
$colorrgb1: rgb(0 0 0 / 9%);
$colorrgba3: rgba(0, 0, 0, 0.09);

$colorebedf3: #ebedf3;
$colorE1: #fbfbfb; //employee
$coloracb8e6: #acb8e6;
$colorbbbfcb: #bbbfcb;
$colorf0eff5: #f0eff5;
$colore6e6e6: #e6e6e6;
$colorc1c1c1: #c1c1c1;
$colore9e9e9: #e9e9e9;
$colorc4c4c4: #c4c4c4;

$colorcdd2df: #cdd2df; //header arrow, disable
$color9d9d9d: #9d9d9d;

$coloracb6e5: #acb6e5; //news tag
$color86fde8: #86fde8; //news tag
$colorEC86FD: #EC86FD; //news tag