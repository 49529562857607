.news-header {
  @include flex_container(row, wrap, space-between, flex-end);
  width: 100%;
  margin-top: -20px;
  margin-bottom: 20px;
  &__name {
    @include font-size_font-weight(24px, 600);
    margin-bottom: 0.1em;
    line-height: 1;
  }
  &__show-all {
    display: block;
    margin-top: 20px;
    margin-bottom: 0.3em;
    color: var(--second-color);
    @include font-size_font-weight(16px, 600);
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    @include link_on_hover_underline(var(--second-color));
  }
}
.news-tags {
  @include flex_container(row, wrap, flex-start, flex-start);
  width: 100%;
  margin-top: 44px;
  &__item {
    display: block;
    margin-top: 15px;
    margin-right: 15.3px;
    padding: 12px 20px;
    color: $colorbbbfcb;
    @include font-size_font-weight(16px, 500);
    text-decoration: none;
    border: 1px solid $colorf0eff5;
    border-radius: 59px;
    &.show,
    &.active,
    &:hover {
      color: var(--white-text);
      background: linear-gradient(114.07deg, $coloracb6e5 15.44%, $color86fde8 122.33%), var(--white-text);
    }
  }
}

.news-count {
  @include font-size_font-weight(14px, 400);
  margin-top: 60px;
  margin-bottom: 20px;
  a {
    @include link_on_hover_underline(var(--second-color));
  }
}

.news {
  width: 100%;
  margin: 0 auto;
  &__content {
    margin: 0 auto;
  }
}
.news-item {
  @include flex_container(column, nowrap, flex-end, flex-start);
  position: relative;
  width: 290px;
  height: 140px;
  margin: 5px 0;
  @media screen and (min-width: 620px) {
    margin: 5px;
  }
  overflow: hidden;
  box-shadow: 0px 0px 5px $colorrgb1;
  &:hover {
    transform: scale(1.03);
  }

  @media screen and (min-width: 760px) {
    width: 360px;
    height: 172.5px;
  }
  &__content {
    @include flex_container(column, nowrap, space-between, flex-start);
    flex: 1;
    position: relative;
    width: 100%;
    padding: 10px;
    z-index: 1;
    @media screen and (min-width: 760px) {
      padding: 30px 10px 10px 20px;
    }
  }
  &-image &__content {
    flex: 0;
    @include flex_container(column, nowrap, flex-end, flex-start);
    background-image: linear-gradient(transparent, $colorrgba2);
  }
  &-image {
    height: 290px;

    @media screen and (min-width: 760px) {
      height: 360px;
    }
  }

  &__title {
    @include font-size_font-weight(18px, 600);
    color: $black-text;
    @media screen and (min-width: 760px) {
      @include font-size(16px);
    }
  }
  &__info {
    @include font-size_font-weight(12px, 400);
    color: $black-text;
  }
  &-image &__title,
  &-image &__info {
    color: var(--white-text);
  }
  &-image &__title {
    margin-bottom: 30px;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.pagination {
  position: relative;
  @include flex_container(column, wrap, space-between, flex-start);
  @media (min-width: 768px) {
    flex-direction: row;
  }
  padding: 20px 0;
  border-top: 1px solid $colore6e6e6;
  border-bottom: 1px solid $colore6e6e6;
  &__count {
    @include font-size_font-weight(18px, 600);
  }
  &__current {
    color: var(--second-color);
  }

  &__link {
    @include link_on_hover_underline(var(--second-color));
    @include font-size_font-weight(18px, 600);
    &-disable {
      color: $colorc4c4c4;
      pointer-events: none;
      &:visited {
        color: $colorc4c4c4;
      }
    }
  }
}
// .pagination {
//   .disabled {
//     display: none;
//   }
// }
.news__title {
  @include font-size_font-weight(18px, 600);
  color: $black-text;
  @media screen and (min-width: 760px) {
    @include font-size(16px);
  }
}
.news__info {
  @include font-size_font-weight(12px, 400);
}

.news-show__title {
  @include font-size_font-weight(30px, 600);
  margin-bottom: 5px;
}

.news-show__date {
  @include font-size_font-weight(13px, 300);
  text-align: left;
  margin-bottom: 25px;
  font-stretch: italic;
}

.news_show__tags {
  display: flex;
  flex-wrap: wrap;
  a {
    display: block;
    padding: 5px 15px;
    border-radius: 59px;
    margin: 10px;
    color: var(--white-text);
    font-weight: 500;
    &:hover {
      transform: scale(1.03);
    }
  }
  &-show {
    background: linear-gradient(114.07deg, $coloracb6e5 15.44%, $color86fde8 122.33%);
  }
  &-hidden {
    background: linear-gradient(108.12deg, var(--second-color) 12.33%, $colorEC86FD 111.01%);
  }
}