.widgets {
  width: 100%;
  // height: fit-content;
  border: 1px solid $colore9e9e9;
  border-top: unset;
  // margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: calc($container-size + $base-page-pading*2)) {
    max-width: 360px;
    display: block;
  }
  &-with-top {
    @media screen and (min-width: 620px) {
      margin-top: 5px;
    }
    box-shadow: 0px 0px 5px $colorrgb1;
    border: none;
  }
}

.widget {
  margin-bottom: 20px;
  max-width: 320px;
  width: 100%;
  @media (min-width: calc($container-size + $base-page-pading*2)) {
    max-width: 360px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &-padding {
    padding: 20px;
  }
  img {
    max-width: 100%;
    object-fit: contain;
  }
  &__name {
    display: inline-block;
    margin-bottom: 20px;
  }
  &__title {
    display: inline;
    @include font-size_font-weight(24px, 600);
  }
  &__border {
    margin-top: 15px;
    width: 100%;
    height: 3px;
    background: var(--main-color);
    background: linear-gradient(90deg, var(--main-color) 0%, var(--second-color) 100%);
  }
  &__more {
    @include font-size_font-weight(14px, 600);
    color: var(--main-color);
  }
  &__info {
    p {
      @include font-size_font-weight(14px, 400);
      margin-bottom: 10px;
      strong {
        @include font-size_font-weight(14px, 500);
      }
    }
  }
}

.content-with-widgets {
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (min-width: 1150px) {
    flex-direction: row;
  }
  .page {
    display: inline;
    flex-shrink: 2;
    width: 100%; 
    margin: 0 auto;
  }
  .page-with-padding {
    padding: 50px 10px 0 0 ;
  }
  .widgets {
    @media (min-width: 1150px) {
      width: 360px;
      flex-basis: 360px;
      flex-shrink: 0;
    }
  }
}
.content-with-widgets-enabled {
  .override.ck-content {
    margin: 0 auto;
    max-width: 740px;
    overflow-x: auto;
  }
}

.news-widgets {
  @media screen and (max-width: calc($container-size + $base-page-pading*2)) {
    display: none;
  }
}