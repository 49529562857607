.events-news {
  @include flex_container(row, wrap, center, space-between);
}

.calendar {
  color: var(--white-text);
  &__date,
  &__day-of-week {
    text-align: center;
    @include font-size_font-weight(16px, 400);
    padding: 6px;
    position: relative;
    line-height: 1.2;
    @media screen and (min-width: 400px) {
      padding: 10px 10px;
    }
  }
  &__date-disabled {
    color: $colorE1 !important;
  }
  &__date-have_events {
    cursor: pointer;
  }
  &__date-have_events:after {
    background-color: var(--white-text);
    border-radius: 50%;
    bottom: 2px;
    left: calc(50% - 2.5px);
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    width: 5px;
  }
  &__date-selected {
    background-color: var(--white-text);
    border-radius: 50%;
    color: var(--main-color) !important;
  }
}

.events-news-controls {
  @include flex_container(column, nowrap, flex-end, center);
  padding: 15px;
  min-height: 370px;
  background: linear-gradient(90deg, var(--thrid-color) 0%, var(--second-color) 100%), linear-gradient(0deg, $colorc4c4c4, $colorc4c4c4);
  flex: 1;
  max-width: 730px;
  @media screen and (min-width: 1030px) {
    padding: 40px 34px 40px 40px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    width: 100%;
  }
  &__title {
    color: var(--white-text);
    @include font-size_font-weight(20px, 600);
  }
}

.events {
  position: relative;
  background: linear-gradient(90deg, var(--thrid-color) 0%, var(--second-color) 100%), linear-gradient(0deg, $colorc4c4c4, $colorc4c4c4);
  @media screen and (min-width: 760px) {
    min-height: 570px;
    flex-shrink: 0;
    flex-basis: 730px;
  }
}

.event-news {
  position: relative;
  overflow: hidden;
  &__image {
    width: 100%;
    max-width: 730px;
  }
  &__prev,
  &__next {
    align-items: center;
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    transition: background-color 0.3s linear;
    width: 40px;
    z-index: 2;
    span {
      border-bottom: 5px solid var(--white-text);
      border-left: 5px solid var(--white-text);
      display: inline-block;
      height: 20px;
      opacity: 0.5;
      transition: opacity 0.3s linear;
      width: 20px;
    }
    &:hover {
      span {
        opacity: 0.9;
      }
    }
  }
  &__next {
    right: 0;
    span {
      margin-right: 10px;
      transform: rotate(225deg);
    }
  }
  &__prev {
    span {
      margin-left: 10px;
      transform: rotate(45deg);
    }
  }
}

.event-news-text {
  @include absolute_full_container;
  @include flex_container(column, nowrap, flex-end, center);
  background-image: linear-gradient(transparent,rgba(0,0,0,.25));
  
  &__wrapper {
    overflow: hidden;
    width: 100%;
    height: 70%;
    padding: 20px;
    @include flex_container(column, nowrap, space-between, center);
    color: var(--white-text);
    text-align: center;
  }
  &__title {
    @include font-size_font-weight(14px, 600);
    line-height: 1.5;
    @media screen and (min-width: 400px) {
      @include font-size(16px);
    }
    @media screen and (min-width: 650px) {
      @include font-size(26px);
    }
  }
  &__more-block {
    display: flex;
    flex-direction: column;
  }
  &__more {
    @include font-size_font-weight(14px, 600);
    color: var(--white-text);
    text-transform: uppercase;
    @media screen and (min-width: 400px) {
      @include font-size(16px);
    }
  }
  &__date {
    @include font-size_font-weight(12px, 500);
    @media screen and (min-width: 400px) {
      @include font-size(14px);
    }
    @media screen and (min-width: 650px) {
      @include font-size(14px);
    }
  }
}

.selects {
  width: 100%;
  display: block;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.select {
  min-width: 75px;
  color: var(--white-text);
  @include font-size_font-weight(16px, 400);
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Montserrat";
  option {
    @include font-size_font-weight(14px, 400);
    color: $black-text;
    background: var(--white-text);
    font-family: "Montserrat";
  }
}
