.cookie-notification {
  padding: 0 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  transform: translateY(100%);
  transition: transform 0.6s;
  &__show {
    transform: translateY(-10%);
  }
  &__body {
    box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.16);
    padding: 20px;
    border-radius: 25px;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.9);
    margin: 0 auto;
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  &__text {
    // display: inline;
    max-width: 500px;
    a {
      color: var(--main-color);
    }
    a:visited {
      color: var(--main-color);
    }
  }
  &__button {
    max-height: 48px;
    padding: 10px 20px;
    color: var(--white-text);
    background-color: var(--main-color);
    border-radius: 25px;
    border: none;
    &:hover {
      opacity: 0.8;
    }
  }
}
