.logo-with-text,
.header__logo-with-text,
.logo-short {
  color: $black-text;
  svg {
    fill: #2a3dad;
  }
}

.header__menu_opened,
.subsitesubmenu_opened {
  .submenu {
    display: flex;
  }
}
.header-white:not(.header-background, .header__menu_opened) {
  color: var(--white-text);
  .header__title,
  .header__menu-link,
  .mburger,
  .header__phone a {
    color: var(--white-text);
  }
  .faculties_arrow-down {
    border-color: var(--white-text);
  }
  .logo-with-text,
  .logo-short {
    color: var(--white-text);
    svg {
      fill: var(--white-text);
    }
  }
}
.header-background {
  background-color: var(--white-text);
  box-shadow: 0 0 13px 7px $colorE2;
  border-radius: 15px;
  top: 0px;
}
.mburger {
  color: #000;
}

.header {
  position: relative;
  @media screen and (min-width: $container-size) {
    position: fixed;
  }
  top: 10px;
  z-index: 2;
  width: 100%;
  max-width: 1890px;
  padding: 10px 0px;
  &__mobile-title {
    text-align: center;
    .header__title-name {
      display: block;
    }
  }
  &__init {
    overflow-x: auto;
    visibility: hidden;
  }
  &__content {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    // max-width: 1920px;
    margin: 0 auto;
    padding: 0 15px;
    background-color: transparent;
  }
  &__brand {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  &__title {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    color: $black-text;
    text-decoration: none;
    word-break: break-word;
    white-space: nowrap;
    &-name {
      @include font-size_font-weight(18px, 700);
      // margin-bottom: 15px;
    }
    &-subtitle {
      @include font-size_font-weight(14px, 400);
    }
  }
  &__menu {
    flex: 1;
    padding: 0 20px;
    &-items {
      flex-grow: 1;
      display: flex;
      justify-content: space-around;
      flex-wrap: nowrap;
    }

    &-link {
      display: flex;
      color: $black-text;
      @include font-size_font-weight(18px, 500);
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:hover {
        color: $black-text;
      }
      &.active {
        position: relative;
        color: var(--main-color) !important;
        &:before {
          content: " ";
          position: absolute;
          bottom: -10px;
          left: 40%;
          width: 5px;
          height: 5px;
          background-color: var(--main-color);
          border-radius: 50%;
        }
        &:after {
          border-top: unset;
          border-bottom: 8px solid $color9d9d9d;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
        }
      }
    }
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__phone {
    // padding-left: 15px;
    white-space: nowrap;
    a {
      color: $black-text;
      text-decoration: none;
    }
    &-number {
      @include font-size_font-weight(18px, 600);
    }
    &-desc {
      @include font-size_font-weight(12px, 400);
    }
  }
  &__burger {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    > span {
      background-color: $black-text;
      width: 2em;
      height: 0.2em;
      margin: 0.26em 0;
      display: block;
      transition: all 0.4s ease;
      transform-origin: 0 0;
    }

    &-active span:nth-child(1) {
      transform: rotate(45deg) translate(1px, -1px);
    }

    &-active span:nth-child(2) {
      transform: scaleX(0);
    }

    &-active span:nth-child(3) {
      transform: rotate(-45deg) translate(1px, 0);
    }
  }
  &__lang {
    position: relative;
    display: flex;
    color: $black-text;
    @include font-size_font-weight(18px, 500);
    text-decoration: none;
    align-items: center;
    padding-right: 20px;
    &-content {
      display: none;
      position: absolute;
      background-color: var(--white-text);
      @include font-size_font-weight(18px, 500);
      box-shadow: 0 0 13px 7px $colorE2;
      border-radius: 5px;
      min-width: 45px;
      // padding: 0px 5px 0px 5px;
      z-index: 10;
      left: -10px;
      top: -1px;
    }
    img {
      margin-right: 6.5px;
    }
    a {
      &:hover {
        background-color: var(--main-color);
        color: var(--white-text);
      }
    }
    &.active &-content {
      display: block;
    }
  }
  &__lang-option {
    position: relative;
    color: $black-text;
    display: flex;
    padding: 5px 10px;
    &:after {
      border-top: unset !important;
      border-bottom: 8px solid $color9d9d9d !important;
      border-right: 6px solid transparent !important;
      border-left: 6px solid transparent !important;
    }
  }
  &__lang-selected {
    padding: 9px 10px;
  }
}

.arrow-down {
  position: relative;
  &:after {
    align-self: center;
    width: 0;
    height: 0;
    margin-left: 10.5px;
    border-top: 8px solid $color9d9d9d;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
  }
}

.submenu {
  min-height: 520px;
  max-height: 520px;
  width: 100%;
  display: none;
  flex-direction: column;

  &__content {
    flex: 1;
  }

  &-line {
    margin: 10px 0;
    opacity: 0.1;
    border-top: 1px solid $color89;
  }

  &-test {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    span {
      @include font-size_font-weight(12px, 600);
    }
  }

  &__content {
    overflow-y: auto;
    scrollbar-color: var(--second-color) $colorebedf3;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 3px;
      background-color: $colorebedf3;
      border-radius: 16px;
      left: 0;
      direction: rtl !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--second-color);
    }
    max-width: $container-size;
    margin: 0 auto;
    padding: 35px 0px;
    width: 100%;
  }
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  padding: 0;
  &__title {
    @include font_size_font_weight(36px, 600);
    color: var(--main-color);
    margin-bottom: 40px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__row {
    width: 250px;
    &:not(:last-child) {
      margin-right: 100px;
    }
  }
  &__link {
    @include font_size_font_weight(14px, 600);
    color: $black-text;
    display: block;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__text {
    @include font_size_font_weight(14px, 600);
    cursor: pointer;
    display: block;
    text-transform: uppercase;
    color: $black-text;
  }
  &__item {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
  }
  &__sublink {
    @include font_size_font_weight(12px, 400);
    color: $black-text;
    margin-left: 10px;
    margin-bottom: 7px;
    text-transform: uppercase;
  }
}

.header__menu_opened {
  background-color: var(--white-text);
  box-shadow: 0 0 13px 7px $colorE2;
  border-radius: 15px;
}
.header__menu__child {
  display: none;
}

.mobile__menu.faculties {
  right: unset;
  left: -440px;
}
.mobile__menu {
  z-index: 20;
  height: 100%;
  width: 100%;
  max-width: 440px;
  position: fixed;
  top: 0;
  right: -440px;
  background-color: var(--main-color);
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 0 0 15px 0;
  overflow-y: auto;
  box-shadow: 0 0 13px 7px $colorE2;
  &__title {
    width: 100%;
    min-height: 50px;
    text-align: center;
    background-color: var(--white-text);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 34px;
    h4 {
      @include font-size_font-weight(16px, 600);
      word-break: break-word;
      margin: 0;
      padding: 0;
    }
  }
  div {
    width: 100%;
  }
  &__link {
    position: relative;
    display: block;
    color: var(--white-text);
    border-bottom: 1px solid var(--white-text);
    padding: 20px 15px;
    width: 100%;
    word-wrap: break-word;
    @include font-size_font-weight(16px, 600);
    a {
      color: var(--white-text);
    }
  }
  &__link-back {
    background-color: var(--second-color);
  }
}

.mobile__menu .closebtn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 54px;
  color: $black-text;
  display: block;
  line-height: 0.5;
}

.mobile__open2 {
  right: 0;
}
.faculties.mobile__open2 {
  left: 0 !important;
}

.faculties_arrow-down {
  display: inline-block;
  clear: both;
  margin-left: 10px;
  padding: 0.2em;
  border: solid $black-text;
  border-width: 0 0.15em 0.15em 0;
  transform: translate(0%, -15%) rotate(45deg);
  transition-duration: 250ms;
  content: "";
}

.subsitemenu {
  position: relative;
  padding: 10px 0;
  &__container {
    display: flex;
    flex-wrap: wrap;
  }
  &__menu-link {
    position: relative;
    cursor: pointer;
  }
  @media screen and (max-width: $container-size) {
    display: none;
  }
}
#subsitesubmenu {
  position: absolute;
  top: 100%;
  z-index: 2;
  background-color: var(--white-text);
  box-shadow: 0 19px 11px 0px #00000017;
}
